import React, { useState, useEffect } from 'react';

const Preloader = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // This function will be called once the window has loaded
    const handleLoad = () => setLoading(false);

    window.addEventListener('load', handleLoad);
    
    // Remove the event listener when the component is unmounted
    return () => window.removeEventListener('load', handleLoad);
  }, []);

  // If not loading, return null so React doesn't render the component
  if (!loading) {
    return null;
  }

  // Otherwise, render the preloader
  return <div id="preloader"></div>;
};

export default Preloader;
