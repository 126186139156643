import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";

import logo1 from "../images/male1.png";
import logo2 from '../images/male.png';

const Navbar = () => {
  const [logo, setLogo] = useState(logo1);
  const [navReduce, setNavReduce] = useState(false);
  const [lightTheme, setLightTheme] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setNavReduce(true);
        setLogo(logo2);
        setLightTheme(false);
      } else {
        setNavReduce(false);
        setLogo(logo1);
        setLightTheme(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const buttonStyle = lightTheme ? { borderColor: 'blue', backgroundColor: '#35455f', color: 'white' } : { borderColor: 'lightblue', backgroundColor: '#ffffff', color: 'black' } ;

  // Function to open CV in a new tab
  const openCV = () => {
    const url = '/Da_ZhuoProjectsCV.pdf';
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <nav
      className={`navbar navbar-b navbar-trans navbar-expand-md fixed-top ${navReduce ? "navbar-reduce" : ""}`}
      id="mainNav"
    >
      <div className="container">
        <a className="navbar-brand" href="#page-top">
          <img src={logo} alt="logo" style={{ maxWidth: "100px" }} />
        </a>
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#navbarDefault"
          aria-controls="navbarDefault"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div className="navbar-collapse collapse" id="navbarDefault">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="nav-link"
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="about"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="nav-link"
              >
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="work"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="nav-link"
              >
                Work
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="projects"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="nav-link"
              >
                Projects
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="nav-link"
              >
                Contact
              </Link>
            </li>
            {/* View CV button */}
            <li className="nav-item ml-auto">
              <button style={buttonStyle} onClick={openCV} class="btn cv-button">
                View My CV!
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
