import React from 'react';

// Importing CSS
import 'animate.css/animate.min.css'; // Assuming animate.css was installed via npm
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Assuming Bootstrap was installed via npm
import 'font-awesome/css/font-awesome.min.css'; // Assuming Font Awesome was installed via npm
import 'lightbox2/dist/css/lightbox.min.css'; // Assuming Lightbox was installed via npm

// Importing components
import Navbar from './components/navbar.jsx';
import Intro from './components/intro.jsx';
import About from './components/about.jsx';
import Experience from './components/experience.jsx';
import Projects from './components/projects.jsx';
import Contact from './components/contact.jsx';
import BackToTop from './components/back-top.jsx';
import Preloader from './components/preloader.jsx';

const App = () => {
  return (
    <>
      <Navbar />
      <Intro />
      <About />
      <Experience />
      <Projects />
      <Contact />
      <BackToTop />
      <Preloader />
    </>
  );
};

export default App;
