import React, { useState } from "react";
import myImage from "../images/Daz.jpg";

const About = () => {
  const [skills] = useState([
    { id: "HTML5_skill", content: "HTML5", porcentage: "80%", value: "80" },
    { id: "CSS3_skill", content: "CSS3", porcentage: "75%", value: "75" },
    {
      id: "Java_skill",
      content: "Java",
      porcentage: "85%",
      value: "85"
    },
    {
      id: "JavaScript_skill",
      content: "JavaScript",
      porcentage: "80%",
      value: "80"
    },
    { 
      id: "PHP_skill", 
      content: "PHP", 
      porcentage: "70%", 
      value: "70" 
    },
    {
      id: "ReactJS_skill",
      content: "ReactJS",
      porcentage: "80%",
      value: "80"
    },
    {
      id: "Python_skill",
      content: "Python",
      porcentage: "75%",
      value: "75"
    },
    {
      id: "Wordpress_skill",
      content: "Wordpress",
      porcentage: "75%",
      value: "75"
    }
  ]);

  const [aboutMe] = useState([
    {
      id: "first-p-about",
      content:
        "I'm Da Zhuo, a dedicated computer science student actively seeking new opportunities in the field. With a passion for innovation and optimization, I am ready to contribute my skills and expertise to a dynamic team."
    },
    {
      id: "second-p-about",
      content:
        "During my first year of CEGEP, I was part of a small team for a start-up that conducted business with Blockchain technology and cryptographic technologies."
    },
    {
      id: "third-p-about",
      content:
        "Then, in an internship, I had the opportunity to learn WordPress from scratch and handle multiple projects simultaneously, which enhanced my autonomy, self-learning skills, and organizational abilities."
    },
    {
      id: "fourth-p-about",
      content:
        "I thrive on ambition and always seek to explore new challenges outside my comfort zone. Collaboration is at the core of my approach, and I value the diversity of perspectives that arise from teamwork."
    },
    {
      id: "fifth-p-about",
      content:
        "My adaptability and commitment to continuous learning make me a valuable asset to any project or organization. If you have an opportunity that aligns with my skills and interests, I'd love to connect and explore how I can contribute to your team."
    }
  ]);

  return (
    <section id="about" className="about-mf sect-pt4 route">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="box-shadow-full">
              <div className="row">
                <div className="col-md-6">
                  <div className="about-me pt-4 pt-md-0">
                    <div className="title-box-2">
                      <h5 className="title-left">About Me</h5>
                    </div>
                  </div>
                  <div className="skill-mf">
                    {skills.map(skill => (
                      <React.Fragment key={skill.id}>
                        <span>{skill.content}</span> <span className="pull-right">{skill.porcentage}</span>
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: skill.porcentage }}
                            aria-valuenow={skill.value}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-sm-6 col-md-5" style={{ margin: "0 auto" }}>
                      <div className="about-img" style={{ textAlign: "center" }}>
                        <img src={myImage} className="img-fluid rounded b-shadow-a" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="about-me pt-4 pt-md-0">
                    {aboutMe.map(content => (
                      <p className="lead" key={content.id}>
                        {content.content}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
