import React, { useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll'; // npm install react-scroll

const BackToTop = () => {
    useEffect(() => {
        const handleScroll = () => {
            const backToTop = document.querySelector('.back-to-top');
            if (window.pageYOffset > 100) {
                backToTop.classList.remove("fadeOut");
                backToTop.style.display = "block";
                backToTop.classList.add("fadeIn");
            } else {
                backToTop.classList.remove("fadeIn");
                backToTop.classList.add("fadeOut");
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup function to remove event listener
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = () => {
        scroll.scrollToTop({
            duration: 1500,
            delay: 5,
            smooth: 'easeInOutExpo',
        });
        return false; // Prevent default action of the anchor
    };

    return (
        <a href="#" className="back-to-top animated" onClick={scrollToTop}>
            <i className="fa fa-chevron-up"></i>
        </a>
    );
};

export default BackToTop;