import React from 'react';
import './stars.scss';
import { ReactTyped as Typed } from 'react-typed';

// Enhanced Intro component as a functional component
const Intro = () => {
  // Define the strings for the Typed component here for easy modification and readability
  const typedStrings = [
    "Full-Stack Developer",
    "Front End Developer",
    "Entrepreneur"
  ];

  return (
    <div id="home" className="intro route bg-image background">
      <div id="stars" />
      <div id="stars2" />
      <div id="stars3" />

      <div className="intro-content display-table">
        <div className="table-cell">
          <div className="container">
            <h1 className="intro-title mb-4">Hello, I am Da Zhuo Xie</h1>
            <p className="intro-subtitle">
              <span className="text-slider-items"></span>
              <strong className="text-slider">
                <Typed
                  strings={typedStrings}
                  typeSpeed={80}
                  backDelay={1100}
                  backSpeed={30}
                  loop
                />
              </strong>
            </p>
            <p className="pt-3">
              <a
                className="btn btn-primary btn js-scroll px-4"
                href="#work"
                role="button"
              >
                View My Work Experience and Projects
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
