import React from 'react';

import CWorld from '../images/cryptureworldimage.png';
import phil from '../images/philLogo.png';
import delisoft from '../images/delisoft.jpg';

const Experience = () => {

  const images = [delisoft, phil, CWorld];

  const titles = [
    "PHP Full Stack Internship", 
    "WordPress Developer", 
    "Co-Founder, Game Designer and UI Designer"
  ];

  const categories = [
    "PHP, HTML5, CSS", 
    "WordPress, Agile, Scrum", 
    "Business Management, Game Design, UI Design, PR and Marketing"
  ];

  const projectLinks = [
    "https://delisoft.ca/",
    "https://phil.ca/",
    "https://cryptureworld.com/"
  ];

  return (
    <section id="work" className="portfolio-mf sect-pt4 route">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="title-box text-center">
              <h3 className="title-a">My Work Experience</h3>
              <p className="subtitle-a">
                Here are some of the companies I have worked for.
              </p>
              <div className="line-mf"></div>
            </div>
          </div>
        </div>
        <div className="row">
          {images.map((img, index) => (
            <div key={index} className="col-md-4">
              <div className="work-box">
                <a href={projectLinks[index]} target="_blank" rel="noopener noreferrer">
                  <div className="work-img">
                    <img src={img} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-12">
                        <h2 className="w-title">{titles[index]}</h2>
                        <div className="w-more">
                          <span className="w-ctegory">{categories[index]}</span>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="w-like">
                          <span className="ion-ios-plus-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Experience;
